import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { FaLinkedin } from "react-icons/fa";
import Flag from "react-world-flags";
import Img from "gatsby-image/withIEPolyfill";


const Team = props => {
	const teams = props.data.allMarkdownRemark.edges

	const roviditesek = {
		en: "gbr",
		hu: "hun",
		ger: "deu",
		spa: "spa",
		rus: "rus",
		kir: "kir",
		esp: "esp",
		ukr: "ukr",
		in: "idn",
		fra: "fra"
	}

	const intl = useIntl()
	return (
		<Layout bodyClass="page-teams" path={props.location.pathname}>
			<SEO title={intl.formatMessage({ id: "Team_title" })} />
			<div id="intro-services" className="intro">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1><FormattedMessage id="Team_title" /></h1>
							<p className="teamintro"><FormattedMessage id="Team_intro" /></p>
						</div>
					</div>
				</div>
			</div>

			<div className="container pb-6">
				<div className="">
					{teams.map(edge =>
						edge.node.frontmatter.lang == props.pageContext.intl.language ? (
							edge.node.frontmatter.weight == 1 ? (
								<div
									key={edge.node.id}
									className="border card p-md-2 p-1 mb-1"
								>
									<div className="team">
										<div className="card-header team-member-header">

											{edge.node.frontmatter.profile && (
												<div className="card-image rounded mb-1">
													<Img
														title={edge.node.frontmatter.title}
														alt={edge.node.frontmatter.title}
														objectFit="contain"
														fluid={edge.node.frontmatter.profile.childImageSharp.fluid}
													/>
												</div>
											)}

											<div className="card-right pt-2 pt-md-1 pl-1">
												<span className="h2">
													{edge.node.frontmatter.title}
												</span>
												<a
													className="member-linkedin ml-1 h5"
													target="_blank"
													rel="noreferrer"
													href={
														"https://" + edge.node.frontmatter.linkedinurl
													}
												>
													<FaLinkedin />
												</a>
												<ul className="card-meta">
													<li>
														<strong>{edge.node.frontmatter.jobtitle}</strong>
													</li>
													<li className="d-none d-lg-block">
														<small>
															<a
																href={"mailto:" + edge.node.frontmatter.email}
															>
																{edge.node.frontmatter.email}
															</a>
														</small>
													</li>
													<li>
														{edge.node.frontmatter.flags ? (
															edge.node.frontmatter.flags.split(',').map(flag =>
																<Flag code={roviditesek[flag] ? roviditesek[flag] : flag}
																	alt={roviditesek[flag] ? roviditesek[flag] : flag}
																	height="12"
																	className="pr-1"
																	fallback={<span></span>}
																/>
															)
														) : null}
													</li>

												</ul>
											</div>

										</div>
										<div className="d-block d-lg-none my-1">
											<a href={"mailto:" + edge.node.frontmatter.email}>
												{edge.node.frontmatter.email}
											</a>
										</div>
										<div
											className="team-content"
											dangerouslySetInnerHTML={{ __html: edge.node.html }}
										/>
									</div>
								</div>
							) : null) : null
					)}
				</div>
				<hr />
				<div className="grid-layout">
					{teams.map(edge =>
						edge.node.frontmatter.lang == props.pageContext.intl.language ? (
							edge.node.frontmatter.weight != 1 ? (
								<div
									key={edge.node.id}
									className="border grid-item card p-md-2 p-1 mb-1"
								>
									<div className="team">
										<div className="card-header team-member-header">

											{edge.node.frontmatter.profile && (
												<div className="card-image rounded mb-1">
													<Img
														title={edge.node.frontmatter.title}
														alt={edge.node.frontmatter.title}
														objectFit="contain"
														fluid={edge.node.frontmatter.profile.childImageSharp.fluid}
													/>
												</div>
											)}

											<div className="card-right pt-2 pt-md-1 pl-1">
												<span className="h2">
													{edge.node.frontmatter.title}
												</span>
												{edge.node.frontmatter.linkedinurl ? (
													<a
														className="member-linkedin ml-1 h5"
														target="_blank"
														rel="noreferrer"
														href={
															"https://" + edge.node.frontmatter.linkedinurl
														}
													>
														<FaLinkedin />
													</a>
												) : null}
												<ul className="card-meta">
													<li>
														<strong>{edge.node.frontmatter.jobtitle}</strong>
													</li>
													<li className="d-none d-lg-block">
														<small>
															<a
																href={"mailto:" + edge.node.frontmatter.email}
															>
																{edge.node.frontmatter.email}
															</a>
														</small>
													</li>
													<li>
														{edge.node.frontmatter.flags ? (
															edge.node.frontmatter.flags.split(',').map(flag =>
																<Flag code={roviditesek[flag] ? roviditesek[flag] : flag}
																	alt={roviditesek[flag] ? roviditesek[flag] : flag}
																	height="12"
																	className="pr-1"
																	fallback={<span></span>}
																/>
															)
														) : null}
													</li>
												</ul>
											</div>

										</div>
										<div className="d-lg-none pl-1 mb-2">
											<a href={"mailto:" + edge.node.frontmatter.email}>
												{edge.node.frontmatter.email}
											</a>
										</div>
										<div
											className="team-content"
											dangerouslySetInnerHTML={{ __html: edge.node.html }}
										/>
									</div>
								</div>
							) : null) : null
					)}
				</div>
			</div>
		</Layout>
	);
};

const teamQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/team/" }
      }
      sort: { fields: [frontmatter___place], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            profile {
              publicURL
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            jobtitle
            linkedinurl
            email
            weight
            lang
            place
            flags
          }
        }
      }
    }
  }
`;

export default props => (
	<StaticQuery
		query={teamQuery}
		render={data => (
			<Team location={props.location} props data={data} {...props} />
		)}
	/>
)
